// #app/interface/portfolio/career.tsx

import React from "react";
import { ElementSection } from "#app/interface/components/composite/element-section";
import SkillsSection from "#app/interface/portfolio/skills-section";

export const Skills = () => {
  return (
    <div className="bg-background">
      <ElementSection
        subheading=" "
        heading="Skills Intro"
        variant="overlay-headline"
      >
        <SkillsSection />
      </ElementSection>
    </div>
  );
};

export default Skills;