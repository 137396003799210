// #app/interface/portfolio/career.tsx

import React from "react";
import { ElementSection } from "#app/interface/components/composite/element-section";
import StackSection from "#app/interface/portfolio/stack-section";

export const Stack = () => {
  return (
    <div className="bg-background">
      <ElementSection
        subheading=" "
        heading="Stack Intro"
        variant="overlay-headline"
      >
        <StackSection />
      </ElementSection>
    </div>
  );
};

export default Stack;