// #app/interface/portfolio/career-section.tsx

import { motion } from "framer-motion";
import React, { type FC } from "react";
import { ElementCarousel, AnimatedText } from "#app/interface/components/composite/element-carousel";
import { type StackData, stackData, initialStack } from "#app/utils/element-data";
import { StackCard } from "./stack-card";

const OtherInfo: FC<{ data: StackData }> = React.memo(({ data }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    className="flex flex-col max-md:pb-5"
    aria-label="Slide details"
  >
    <AnimatedText
      className="spacing overflow-hiddent text-zinc-900 [.dark_&]:text-white"
      data={data.category || ''}
    />
    <AnimatedText
      className="my-1 text-4xl font-semibold md:my-3 md:text-8xl md:leading-[100px] text-zinc-900 [.dark_&]:text-white"
      data={data.title || ''}
    />
    <AnimatedText
      className="text-xs text-zinc-900 [.dark_&]:text-white"
      data={data.description || ''}
    />
  </motion.div>
));
OtherInfo.displayName = "OtherInfo";

const SkillsSection: FC = () => {
  type TransformedData = StackData & {
    color: StackData['color'] & string;
  };

  const createGradientColor = (color: StackData['color']): StackData['color'] & string => {
    return {
      ...color,
      toString: () => color.start
    } as StackData['color'] & string;
  };

  const transformedStackData = stackData().map(item => ({
    ...item,
    color: createGradientColor(item.color)
  })) as TransformedData[];

  const transformedInitialData: TransformedData = {
    ...initialStack,
    color: createGradientColor(initialStack.color)
  };

  const renderSlide = (item: TransformedData) => (
    <StackCard
      id={item.id}
      title={item.title}
      content={item.description}
      location={item.category}
      color={item.color}
    />
  );

  const renderInfo = (data: TransformedData) => (
    <OtherInfo data={data} />
  );

  return (
    <ElementCarousel
      data={transformedStackData}
      initialData={transformedInitialData}
      renderSlide={renderSlide}
      renderInfo={renderInfo}
    />
  );
};

export default SkillsSection;