// #app/interface/portfolio/footer.tsx

import { Icon } from "#app/interface/foundations/icons/icon";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "#app/interface/shadcn/accordion";
import { Separator } from "#app/interface/shadcn/ui/separator";
import { motion } from "framer-motion";

const sections = [
  {
    title: "Product",
    links: [
      { name: "Overview", href: "#" },
      { name: "Pricing", href: "#" },
      { name: "Marketplace", href: "#" },
      { name: "Features", href: "#" },
      { name: "Integrations", href: "#" },
      { name: "Pricing", href: "#" },
    ],
  },
  {
    title: "Company",
    links: [
      { name: "About", href: "#" },
      { name: "Team", href: "#" },
      { name: "Blog", href: "#" },
      { name: "Careers", href: "#" },
      { name: "Contact", href: "#" },
      { name: "Privacy", href: "#" },
    ],
  },
  {
    title: "Resources",
    links: [
      { name: "Help", href: "#" },
      { name: "Sales", href: "#" },
      { name: "Advertise", href: "#" },
    ],
  },
];

export const Footer = () => {
  return (
    <section className="pt-24 pb-4 md:pb-8 bg-background">
      <div className="container max-w-5xl px-2 md:px-6">
        {/* Top Separator */}
        <Separator className="mb-10 md:mb-16 bg-border block" />
        
        <footer>
          <div className="grid grid-cols-4 gap-x-8 gap-y-6 md:grid-cols-6">
            <div className="col-span-4 flex justify-between h-full flex-col md:col-span-3 lg:col-span-3">
              <div className="flex w-full items-end justify-between">
                <div className="flex flex-col">
                  <span className="text-[calc(1.8rem)] md:text-[calc(2rem)] pb-3 md:pb-2 font-semibold text-primary opacity-90 leading-tight">Dony Alior</span>
                  <span className="flex items-center text-caption md:text-subhead text-tertiary">
                    Built with<span className="text-primary flex items-center ml-1">
                      ellemments
                      <Icon name="arrow-top-right" className="ml-1 h-4 w-4" />
                    </span>
                  </span>
                </div>
                
                <div className="flex flex-col items-start gap-2 md:hidden">
                  <div className="flex items-center gap-4">
                    <a href="/cv" className="flex items-center text-caption text-primary leading-tight">
                      CV
                      <Icon name="arrow-top-right" className="ml-1 h-4 w-4" />
                    </a>
                    <span className="text-caption text-tertiary">|</span>
                    <a href="/contact" className="flex items-center text-caption text-primary leading-tight">
                      Contact
                      <Icon name="arrow-top-right" className="ml-1 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="hidden md:flex flex-col items-start gap-2 mt-8">
                <div className="flex items-center gap-5">
                  <a href="/cv" className="flex items-center text-subhead text-primary">
                    CV
                    <Icon name="arrow-top-right" className="ml-1 h-4 w-4" />
                  </a>
                  <span className="text-caption text-tertiary">|</span>
                  <a href="/contact" className="flex items-center text-subhead text-primary">
                    Contact
                    <Icon name="arrow-top-right" className="ml-1 h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>

         
            {/* Mobile Accordion (hidden on md and above) */}

            <div className="col-span-4 pt-2 block md:hidden">
            <Separator className="my-8 bg-border" />
              <Accordion type="single" collapsible>
                {sections.map((section, sectionIdx) => (
                  <AccordionItem key={sectionIdx} value={`item-${sectionIdx}`}>
                    <AccordionTrigger className="flex items-center justify-between py-3 [&>svg]:hidden">
                      <span className="text-subhead text-primary leading-tight">{section.title}</span>
                      <div className="flex items-center">
                        <Icon name="plus" className="h-3.5 w-3.5 transition-transform duration-200 [&[data-state=open]]:hidden" />
                        <Icon name="minus" className="hidden h-3.5 w-3.5 transition-transform duration-200 [&[data-state=open]]:block" />
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="space-y-2">
                        {section.links.map((link, linkIdx) => (
                          <li key={linkIdx}>
                            <a href={link.href} className="text-caption text-interactive leading-normal">
                              {link.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>

            {/* Desktop Links (hidden below md) */}
            {sections.map((section, sectionIdx) => (
              <div key={sectionIdx} className="hidden md:block md:col-span-1">
                <h3 className="mb-2.5 text-subhead text-primary leading-tight">{section.title}</h3>
                <ul className="space-y-2">
                  {section.links.map((link, linkIdx) => (
                    <li key={linkIdx}>
                      <a href={link.href} className="text-caption text-interactive text-tertiary leading-normal">
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Bottom Separator */}
          <Separator className="mb-0 md:mb-5 mt-16 bg-border block" />

          <div className="flex flex-col-reverse justify-between gap-4 md:flex-row md:items-center">
            <ul className="flex items-center gap-4">
              <li>
                <a href="#" className="text-caption  text-tertiary">
                  Terms and Conditions
                </a>
              </li>
              <li className="flex md:hidden">
                <span className="text-caption text-tertiary px-0.5">|</span>
              </li>
              <li>
                <a href="#" className="text-caption  text-tertiary">
                  Privacy Policy
                </a>
              </li>
            </ul>
            <p className="max-md:pt-4 text-caption text-tertiary">
              © 2025 ellemments. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </section>
  );
};

