// #app/interface/portfolio/career-section.tsx

import { motion } from "framer-motion";
import React, { type FC } from "react";
import { ElementCarousel, AnimatedText } from "#app/interface/components/composite/element-carousel";
import { type CareerData, careerData, initialCareer } from "#app/utils/element-data";
import { CareerCard } from "./career-card";

const OtherInfo: FC<{ data: CareerData }> = React.memo(({ data }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    className="flex flex-col max-md:pb-5"
    aria-label="Slide details"
  >
    <AnimatedText
      className="spacing overflow-hidden text-zinc-900 [.dark_&]:text-white"
      data={data.location || ''}
    />
    <AnimatedText
      className="my-1 text-4xl font-semibold md:my-3 md:text-8xl md:leading-[100px] text-zinc-900 [.dark_&]:text-white"
      data={data.title || ''}
    />
    <AnimatedText
      className="text-xs text-zinc-900 [.dark_&]:text-white"
      data={data.description || ''}
    />
  </motion.div>
));
OtherInfo.displayName = "OtherInfo";

const CareerSection: FC = () => {
  type TransformedData = CareerData & {
    color: CareerData['color'] & string;
  };

  const createGradientColor = (color: CareerData['color']): CareerData['color'] & string => {
    return {
      ...color,
      toString: () => color.start
    } as CareerData['color'] & string;
  };

  const transformedCareerData = careerData().map(item => ({
    ...item,
    color: createGradientColor(item.color)
  })) as TransformedData[];

  const transformedInitialData: TransformedData = {
    ...initialCareer,
    color: createGradientColor(initialCareer.color)
  };

  const renderSlide = (item: TransformedData) => (
    <CareerCard
      id={item.id}
      title={item.title}
      content={item.description}
      location={item.location}
      color={item.color}
    />
  );

  const renderInfo = (data: TransformedData) => (
    <OtherInfo data={data} />
  );

  return (
    <ElementCarousel
      data={transformedCareerData}
      initialData={transformedInitialData}
      renderSlide={renderSlide}
      renderInfo={renderInfo}
    />
  );
};

export default CareerSection;