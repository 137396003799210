// #app/interface/portfolio/hero.tsx
import { Link } from '@remix-run/react';
import { Icon } from '#app/interface/foundations/icons/icon';

// Constants
const LINKS = {
  cv: {
    to: '/cv',
    text: 'CV',
  },
  contact: {
    to: '/contact',
    text: 'Contact',
  },
} as const;

// Subcomponents
const NameHeader = () => (
  <h1 className="flex w-full justify-between text-[clamp(4rem,22vw,12rem)] font-semibold leading-[0.9] tracking-tight max-sm:text-[clamp(4rem,22vw,12rem)] md:text-[clamp(4rem,22vw,14rem)] lg:text-[clamp(4rem,25vw,14.5rem)] subpixel-antialiased [-webkit-font-smoothing:antialiased] [text-rendering:optimizeLegibility]">
    <span className="-ml-[0.1em]">Dony</span>
    <span className="-ml-[0.1em]">Alior</span>
  </h1>
);

const NavigationLink = ({ to, children }: { to: string; children: React.ReactNode }) => (
  <Link to={to} className="flex items-center text-inherit hover:underline">
    {children}
    <Icon name="arrow-top-right" className="ml-1 h-4 w-4 text-inherit" />
  </Link>
);

const SubtitleSection = () => (
  <div className="mt-2 grid grid-cols-2">
    <div>
      <span className="text-base -ml-[0.1em]">Product Engineer</span>
    </div>
    <div className="flex justify-between max-sm:justify-end">
      <div className="pl-10 max-sm:hidden lg:pl-12">
        <NavigationLink to={LINKS.cv.to}>{LINKS.cv.text}</NavigationLink>
      </div>
      <div className="pr-0.2 sm:pr-2 md:pr-8 lg:pr-10">
        <NavigationLink to={LINKS.contact.to}>{LINKS.contact.text}</NavigationLink>
      </div>
    </div>
  </div>
);

const Description = () => (
  <p className="max-w-md text-center text-lg leading-relaxed md:text-xl">
    Combining unique design and rich technology, we build digital products exactly
    as they were designed, without shortcuts or simplifications.
  </p>
);

const LocationInfo = () => (
  <div className="-mx-[0.1em]">
    <p className="text-sm text-inherit">Based in</p>
    <p className="text-sm text-inherit text-zinc-400">Tokyo, Japan</p>
  </div>
);

const ScrollButton = () => (
  <button className="p-2 transition-transform hover:translate-y-1">
    <Icon name="arrow-down" className="h-8 w-8" />
  </button>
);

// Main component
const Hero = () => {
  return (
    <section className="relative h-screen w-full">
      <div className="mx-auto h-full max-w-5xl px-4 md:px-6">
        <div className="grid h-full grid-rows-[auto_1fr_auto] overflow-x-hidden pt-6 pb-2">
          {/* Top Section */}
          <div className="pt-10">
            <div className="flex flex-col">
              <NameHeader />
              <SubtitleSection />
            </div>
          </div>

          {/* Middle Section */}
          <div className="flex items-center justify-center">
            <Description />
          </div>

          {/* Bottom Section */}
          <div className="flex items-center justify-between pb-4">
            <LocationInfo />
            <ScrollButton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;