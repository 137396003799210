// #app/utils/element-data.ts

export interface GradientColor {
  start: string;
  end: string;
}

// Skill Types and Data
export interface SkillData {
  id: string;
  color: GradientColor;
  title: string;
  description: string;
  skill: string;
}

const defaultSkill: SkillData = {
  id: "skill-1",
  color: {
    start: "rgba(0,0,255,1)",
    end: "rgba(108,200,255,1)"
  },
  title: "Python Development",
  description: "Expertise in building scalable applications with Python, focusing on Django and FastAPI frameworks",
  skill: "Backend Development"
};

export function skillData(): SkillData[] {
  return [
    defaultSkill,
    {
      id: "skill-2",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "React & Next.js",
      description: "Creating modern web applications with React, Next.js, and TypeScript",
      skill: "Frontend Development"
    },
    {
      id: "skill-3",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "Cloud Architecture",
      description: "Designing and implementing cloud-native solutions using AWS and Azure",
      skill: "Cloud Infrastructure"
    },
    {
      id: "skill-4",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "DevOps & CI/CD",
      description: "Setting up automated pipelines and maintaining development infrastructure",
      skill: "DevOps"
    },
    {
      id: "skill-5",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "Database Design",
      description: "Expertise in SQL and NoSQL database design and optimization",
      skill: "Database Management"
    },
    {
      id: "skill-6",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "System Architecture",
      description: "Designing scalable and maintainable system architectures",
      skill: "Architecture"
    }
  ];
}

// Career Types and Data
export interface CareerData {
  id: string;
  color: GradientColor;
  title: string;
  company: string;
  location: string;
  period: string;
  description: string;
  responsibilities: string[];
}

const defaultCareer: CareerData = {
  id: "career-1",
  color: {
    start: "rgba(0,0,255,1)",
    end: "rgba(108,200,255,1)"
  },
  title: "Product Development Manager",
  company: "Rakuten Group Inc.",
  location: "Tokyo, Japan",
  period: "2024 - Present",
  description: "Leading development of enterprise-scale applications and mentoring junior developers",
  responsibilities: [
    "Architecting scalable solutions",
    "Team leadership and mentoring",
    "Code review and quality assurance"
  ]
};

export function careerData(): CareerData[] {
  return [
    defaultCareer,
    {
      id: "career-2",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "Senior Software Engineer",
      company: "Infinity LLC",
      location: "Tokyo, Japan",
      period: "2022 - 2024",
      description: "Developed and maintained multiple client-facing applications",
      responsibilities: [
        "Full-stack application development",
        "API design and implementation",
        "Performance optimization"
      ]
    },
    {
      id: "career-3",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      title: "Data Science Consultant",
      company: "NTT DATA",
      location: "Tokyo, Japan",
      period: "2020 - 2022",
      description: "Built robust backend systems and microservices",
      responsibilities: [
        "Backend service development",
        "Database optimization",
        "System integration"
      ]
    },
    {
      id: "career-4",
      color: {
        start: "rgba(4, 4, 4, 1)",
        end: "rgba(47, 47, 47, 1)"
      },
      title: "Senior Web Engineer",
      company: "Gipocrat",
      location: "Tokyo, Japan",
      period: "2016 - 2018",
      description: "Worked on cloud-based solutions and infrastructure",
      responsibilities: [
        "Cloud infrastructure development",
        "DevOps implementation",
        "Monitoring and logging"
      ]
    }
  ];
}

// Tech Stack Types and Data
export interface StackData {
  id: string;
  color: GradientColor;
  category: string;
  title: string;
  description: string;
  technologies: string[];
  proficiency: number;
}

const defaultStack: StackData = {
  id: "stack-1",
  color: {
    start: "rgba(0,0,255,1)",
    end: "rgba(108,200,255,1)"
  },
  category: "Frontend",
  title: "Modern Web Development",
  description: "Expertise in modern web technologies and frameworks",
  technologies: ["React", "Next.js", "TypeScript", "Tailwind CSS"],
  proficiency: 90
};

export function stackData(): StackData[] {
  return [
    defaultStack,
    {
      id: "stack-2",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      category: "Backend",
      title: "Server Development",
      description: "Building robust and scalable backend services",
      technologies: ["Python", "Node.js", "Django", "Express"],
      proficiency: 85
    },
    {
      id: "stack-3",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      category: "Database",
      title: "Data Management",
      description: "Database design and optimization",
      technologies: ["PostgreSQL", "MongoDB", "Redis", "Elasticsearch"],
      proficiency: 80
    },
    {
      id: "stack-4",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      category: "DevOps",
      title: "Infrastructure & Deployment",
      description: "Cloud infrastructure and CI/CD",
      technologies: ["AWS", "Docker", "Kubernetes", "GitHub Actions"],
      proficiency: 75
    },
    {
      id: "stack-5",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      category: "Testing",
      title: "Quality Assurance",
      description: "Testing and quality assurance tools",
      technologies: ["Jest", "Cypress", "PyTest", "Selenium"],
      proficiency: 85
    },
    {
      id: "stack-6",
      color: {
        start: "rgba(0,0,255,1)",
        end: "rgba(108,200,255,1)"
      },
      category: "Tools",
      title: "Development Tools",
      description: "Development and productivity tools",
      technologies: ["Git", "VS Code", "Postman", "Jira"],
      proficiency: 90
    }
  ];
}

// Export default elements
export const initialSkill = defaultSkill;
export const initialCareer = defaultCareer;
export const initialStack = defaultStack;

// Utility type for all element types
export type ElementData = SkillData | CareerData | StackData;

// Export a function to get all data
export function getAllData() {
  return {
    skills: skillData(),
    career: careerData(),
    stack: stackData()
  };
}