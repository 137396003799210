// #app/interface/components/composite/element-carousel.tsx

import { AnimatePresence, motion, type Variants, useMotionValue, type PanInfo } from "framer-motion";
import React, { useCallback, useMemo, useRef, type FC } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { type GradientColor } from "#app/utils/element-data";
// Constants
const TRANSITION_DELAY = 500;
const ANIMATION_EASE = [0.455, 0.03, 0.515, 0.955];
const DRAG_THRESHOLD = 50;

export interface BaseSlideData {
  id: string;
  color: GradientColor;
}


export interface CurrentSlideData<T extends BaseSlideData> {
  data: T;
  index: number;
}

// Animation variants
export const textAnimationVariants: Variants = {
  hidden: {
    y: "100%",
    transition: { ease: ANIMATION_EASE, duration: 0.85 },
  },
  visible: {
    y: 0,
    transition: { ease: ANIMATION_EASE, duration: 0.75 },
  },
};

// Utility Components
export const AnimatedText: FC<{ data?: string; className?: string }> = React.memo(
  ({ data, className }) => (
    <span style={{ overflow: "hidden", display: "inline-block" }}>
      <motion.p className={className} variants={textAnimationVariants} key={data}>
        {data}
      </motion.p>
    </span>
  )
);
AnimatedText.displayName = "AnimatedText";

export const SliderButton: FC<{
  children: React.ReactNode;
  handleClick: () => void;
  ariaLabel: string;
}> = React.memo(({ children, handleClick, ariaLabel }) => (
  <button
    aria-label={ariaLabel}
    className="flex h-14 w-14 items-center justify-center rounded-full border-[1px] border-foreground text-zinc-900 [.dark_&]:text-white transition-all duration-300 ease-in-out hover:bg-background hover:text-black active:bg-background active:text-black focus:bg-background focus:text-black focus-visible:outline-none group"
    onClick={handleClick}
  >
    {children}
  </button>
));
SliderButton.displayName = "SliderButton";

export const Background: FC<{
  transitionData: BaseSlideData;
  currentSlideData: CurrentSlideData<BaseSlideData>;
}> = React.memo(() => (
  <div className="absolute left-0 top-0 h-full w-full bg-secondary" />
));


export const Progress: FC<{ curIndex: number; length: number }> = React.memo(
  ({ curIndex, length }) => {
    const progressWidth = useMemo(
      () => `${((curIndex + 1) / length) * 100}%`,
      [curIndex, length]
    );

    return (
      <>
        <div className="flex h-[1px] flex-1 items-center rounded-full bg-black [.dark_&]:bg-white">
          <div
            style={{ width: progressWidth }}
            className="h-[1px] rounded-full bg-zinc-700 [.dark_&]:bg-grey-700 transition-all duration-300"
          />
        </div>
        <motion.div
          key={curIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          className="flex items-center text-4xl font-medium text-zinc-900 [.dark_&]:text-white"
        >
          {String(curIndex + 1).padStart(2, '0')}
        </motion.div>
      </>
    );
  }
);
Progress.displayName = "Progress";

interface ControlsProps<T extends BaseSlideData> {
  sliderData: T[];
  data: T[];
  transitionData: T;
  currentSlideData: CurrentSlideData<T>;
  handleData: React.Dispatch<React.SetStateAction<T[]>>;
  handleTransitionData: React.Dispatch<React.SetStateAction<T>>;
  handleCurrentSlideData: React.Dispatch<React.SetStateAction<CurrentSlideData<T>>>;
}

export const Controls = <T extends BaseSlideData>({
  sliderData,
  data,
  transitionData,
  currentSlideData,
  handleData,
  handleTransitionData,
  handleCurrentSlideData,
}: ControlsProps<T>) => {
  const handlePrev = useCallback(() => {
    if (data.length === 0) return;

    const lastItem = data[data.length - 1];
    if (!lastItem) return;

    handleData(prevData => [
      transitionData,
      ...prevData.slice(0, prevData.length - 1),
    ]);

    const nextIndex = sliderData.findIndex(ele => ele.id === lastItem.id);
    handleCurrentSlideData({
      data: transitionData,
      index: nextIndex >= 0 ? nextIndex : 0,
    });
    handleTransitionData(lastItem);
  }, [data, transitionData, sliderData, handleData, handleCurrentSlideData, handleTransitionData]);

  const handleNext = useCallback(() => {
    if (data.length === 0) return;

    const firstItem = data[0];
    if (!firstItem) return;

    handleData(prev => prev.slice(1));

    const nextIndex = sliderData.findIndex(ele => ele.id === firstItem.id);
    handleCurrentSlideData({
      data: transitionData,
      index: nextIndex >= 0 ? nextIndex : 0,
    });
    handleTransitionData(firstItem);

    setTimeout(() => {
      handleData(newData => [...newData, transitionData]);
    }, TRANSITION_DELAY);
  }, [data, transitionData, sliderData, handleData, handleCurrentSlideData, handleTransitionData]);

  return (
    <div className="flex items-center gap-3 py-10 md:py-5 px-0 md:px-1 max-md:pr-2 md:pr-6">
      <SliderButton handleClick={handlePrev} ariaLabel="Previous slide">
        <IoIosArrowBack className="text-xl" />
      </SliderButton>
      <SliderButton handleClick={handleNext} ariaLabel="Next slide">
        <IoIosArrowForward className="text-xl" />
      </SliderButton>
      <Progress curIndex={currentSlideData.index} length={sliderData.length} />
    </div>
  );
};

interface DraggableSlidesProps<T extends BaseSlideData> {
  data: T[];
  handleNext: () => void;
  handlePrev: () => void;
  renderSlide: (item: T) => React.ReactNode;
}

export const DraggableSlides = <T extends BaseSlideData>({
  data,
  handleNext,
  handlePrev,
  renderSlide
}: DraggableSlidesProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dragX = useMotionValue(0);

  const handleDragEnd = (_: never, info: PanInfo) => {
    const shouldTransition = Math.abs(info.offset.x) > DRAG_THRESHOLD ||
      Math.abs(info.velocity.x) > 500;

    if (shouldTransition) {
      if (info.offset.x > 0) {
        handlePrev();
      } else {
        handleNext();
      }
    }

    dragX.set(0);
  };

  const handleWheel = useCallback((event: React.WheelEvent) => {
    const isHorizontal = Math.abs(event.deltaX) > Math.abs(event.deltaY) || event.shiftKey;
    if (!isHorizontal) return;

    const scrollDelta = event.deltaX || event.deltaY;
    if (Math.abs(scrollDelta) > DRAG_THRESHOLD) {
      if (scrollDelta > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      event.preventDefault();
    }
  }, [handleNext, handlePrev]);

  return (
    <div
      ref={containerRef}
      className="relative w-full overflow-visible"
      onWheel={handleWheel}
    >
      <motion.div
        className="flex gap-6 relative z-50 will-change-transform"
        drag="x"
        dragConstraints={containerRef}
        dragElastic={0.2}
        dragMomentum={false}
        onDragEnd={handleDragEnd}
        style={{ x: dragX }}
      >
        {data.map((item) => (
          <div key={item.id} className="flex-shrink-0 transform-gpu">
            {renderSlide(item)}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export interface ElementCarouselProps<T extends BaseSlideData> {
  data: T[];
  initialData: T;
  renderSlide: (item: T) => React.ReactNode;
  renderInfo: (data: T) => React.ReactNode;
  className?: string;
}

export const ElementCarousel = <T extends BaseSlideData>({
  data: initialSliderData,
  initialData,
  renderSlide,
  renderInfo,
  className = ""
}: ElementCarouselProps<T>) => {
  const [data, setData] = React.useState<T[]>(() => initialSliderData.slice(1));
  const [transitionData, setTransitionData] = React.useState<T>(initialData);
  const [currentSlideData, setCurrentSlideData] = React.useState<CurrentSlideData<T>>({
    data: initialData,
    index: 0,
  });

  const handleNext = useCallback(() => {
    if (data.length === 0) return;

    const firstItem = data[0];
    if (!firstItem) return;

    setData(prev => prev.slice(1));

    const nextIndex = initialSliderData.findIndex(ele => ele.id === firstItem.id);
    setCurrentSlideData({
      data: transitionData,
      index: nextIndex >= 0 ? nextIndex : 0,
    });
    setTransitionData(firstItem);

    setTimeout(() => {
      setData(newData => [...newData, transitionData]);
    }, TRANSITION_DELAY);
  }, [data, transitionData, initialSliderData]);

  const handlePrev = useCallback(() => {
    if (data.length === 0) return;

    const lastItem = data[data.length - 1];
    if (!lastItem) return;

    setData(prevData => [
      transitionData,
      ...prevData.slice(0, prevData.length - 1),
    ]);

    const nextIndex = initialSliderData.findIndex(ele => ele.id === lastItem.id);
    setCurrentSlideData({
      data: transitionData,
      index: nextIndex >= 0 ? nextIndex : 0,
    });
    setTransitionData(lastItem);
  }, [data, transitionData, initialSliderData]);

  return (
    <main className={`relative min-h-screen select-none overflow-hidden text-white antialiased ${className}`}>
      <Background
        key="background"
        transitionData={transitionData}
        currentSlideData={currentSlideData}
      />
      <AnimatePresence>
        <div key="content" className="absolute z-20 h-full w-full">
          <div className="flex h-full w-full grid-cols-10 xl:pl-28 2xl:pl-32 flex-col md:grid">
            <div className="col-span-4 mb-3 flex h-full flex-1 flex-col justify-end pl-2 md:mb-0 md:justify-center max-md:py-10">
              {renderInfo(transitionData || currentSlideData.data)}
            </div>
            <div className="col-span-6 flex h-full flex-1 flex-col justify-start px-2 pr-0 md:px-0 md:justify-center">
              <div className="relative w-full overflow-hidden">
                <DraggableSlides
                  data={data}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  renderSlide={renderSlide}
                />
              </div>
              <Controls
                currentSlideData={currentSlideData}
                data={data}
                transitionData={transitionData}
                handleData={setData}
                handleTransitionData={setTransitionData}
                handleCurrentSlideData={setCurrentSlideData}
                sliderData={initialSliderData}
              />
            </div>
          </div>
        </div>
      </AnimatePresence>
    </main>
  );
};

export default ElementCarousel;