// #app/interface/portfolio/career-section.tsx

import { motion } from "framer-motion";
import React, { type FC } from "react";
import { ElementCarousel, AnimatedText } from "#app/interface/components/composite/element-carousel";
import { type SkillData, skillData, initialSkill } from "#app/utils/element-data";
import { SkillsCard } from "./skills-card";

const OtherInfo: FC<{ data: SkillData }> = React.memo(({ data }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    className="flex flex-col max-md:pb-5"
    aria-label="Slide details"
  >
    <AnimatedText
      className="spacing overflow-hidden text-zinc-900 [.dark_&]:text-white"
      data={data.skill || ''}
    />
    <AnimatedText
      className="my-1 text-4xl font-semibold md:my-3 md:text-8xl md:leading-[100px] text-zinc-900 [.dark_&]:text-white"
      data={data.title || ''}
    />
    <AnimatedText
      className="text-xs text-zinc-900 [.dark_&]:text-white"
      data={data.description || ''}
    />
  </motion.div>
));
OtherInfo.displayName = "OtherInfo";

const SkillsSection: FC = () => {
  type TransformedData = SkillData & {
    color: SkillData['color'] & string;
  };

  const createGradientColor = (color: SkillData['color']): SkillData['color'] & string => {
    return {
      ...color,
      toString: () => color.start
    } as SkillData['color'] & string;
  };

  const transformedSkillsData = skillData().map(item => ({
    ...item,
    color: createGradientColor(item.color)
  })) as TransformedData[];

  const transformedInitialData: TransformedData = {
    ...initialSkill,
    color: createGradientColor(initialSkill.color)
  };

  const renderSlide = (item: TransformedData) => (
    <SkillsCard
      id={item.id}
      title={item.title}
      content={item.description}
      location={item.skill}
      color={item.color}
    />
  );

  const renderInfo = (data: TransformedData) => (
    <OtherInfo data={data} />
  );

  return (
    <ElementCarousel
      data={transformedSkillsData}
      initialData={transformedInitialData}
      renderSlide={renderSlide}
      renderInfo={renderInfo}
    />
  );
};

export default SkillsSection;