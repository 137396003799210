// #app/interface/portfolio/career-card.tsx

import { motion, type HTMLMotionProps } from "framer-motion";
import React, { type FC } from "react";
import { ElementCard } from '#app/interface/components/composite/element-card';
import { type GradientColor } from "#app/utils/element-data";

interface StackCardProps {
  id: string;
  title: string;
  content: string;
  location: string;
  color: GradientColor;
}

export const StackCard: FC<StackCardProps & HTMLMotionProps<"div">> = ({
  id,
  title,
  content,
  location,
  color,
  ...props
}) => {
  const defaultContent = (
    <>
      <motion.h1
        className="text-xl font-medium leading-6 text-gray-900"
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {title}
      </motion.h1>
      {location && (
        <motion.p 
          className="mt-1 text-xs text-gray-600"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {location}
        </motion.p>
      )}
    </>
  );

  const detailContentElement = content && (
    <motion.p
      className="text-white font-medium text-lg"
      initial={{ y: 40, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 40, opacity: 0 }}
      transition={{ 
        duration: 0.4,
        delay: 0.15,
        ease: "easeOut"
      }}
    >
      {content}
    </motion.p>
  );

  return (
    <ElementCard
      id={id}
      color={color}
      defaultContent={defaultContent}
      detailContent={detailContentElement}
      className="h-72 min-w-[280px] md:h-[360px] md:min-w-[300px] lg:h-[380px] lg:min-w-[340px] xl:h-[480px] xl:min-w-[400px]"
      {...props}
    />
  );
};

export default StackCard;