import { motion } from "framer-motion";

interface StackCTAProps {
  text?: string;
}

export default function StackCTA({ 
  text = "Call To Action"
}: StackCTAProps) {
  return (
    <section className="relative h-screen">
      <div className="relative h-full bg-background">
        <div className="sticky top-0 w-full h-screen max-w-5xl mx-auto px-2 md:px-6 flex items-center justify-start overflow-hidden">
          <motion.div
            initial={{ y: "100%" }}
            whileInView={{ y: "0%" }}
            transition={{ duration: 1.5, ease: [0.76, 0, 0.24, 1] }}
            viewport={{ once: true }}
            className="text-inherit text-4xl md:text-5xl font-semibold leading-relaxed tracking-tight"
          >
            {text}
          </motion.div>
        </div>
      </div>
    </section>
  );
}