// #app/interface/portfolio/career.tsx

import React from "react";
import { ElementSection } from "#app/interface/components/composite/element-section";
import CareerSection from "#app/interface/portfolio/career-section";

export const Career = () => {
  return (
    <div className="bg-background">
      <ElementSection
        subheading=" "
        heading="Career Intro"
        variant="overlay-headline"
      >
        <CareerSection />
      </ElementSection>
    </div>
  );
};

export default Career;